import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
    providedIn: 'root'
})

export class PaymentGatewayConfigService {

    private _baseUrl: string = '';
    private _token: string  = '';

    public constructor(public appService: AppService, public http: HttpClient, @Inject('CREDITORPORTAL_API_BASE_URL') baseurl: string) {
        this._baseUrl = baseurl;

        const loggedUserSession = localStorage.getItem('userSession');
        if(loggedUserSession){
          const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
          this._token = loggedUserToken;
        }
    }

    public getPaymentGatewayConfig = (creditorId: string): Observable<any> => {
      const apipath: string = "Creditor/payment-gateway-config";
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptionsWithHeaderKey('X-Creditorid',creditorId);

      return this.http.get<any>(fullPath, options);
    }

    public putPaymentGatewayConfig = (creditCardGateway: string, debitCardGateway: string, bankAccountGateway: string, creditorId: string): Observable<any> => {
      const apipath: string = 'Creditor/payment-gateway-config';
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptionsWithHeaderKey('X-Creditorid',creditorId);
      const params: any = { creditCardGateway, debitCardGateway, bankAccountGateway};

      return this.http.put<any>(fullPath, params, options);
   }
}
