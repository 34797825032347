<div class="px-3">
  <div class="text-lg">
    Payment Gateway Config
  </div>

  <div class="mt-6">

    <p-floatLabel>
      <p-autoComplete 
         styleClass="w-5"
         [(ngModel)]="selectedCreditor" 
         [suggestions]="filteredCreditors"  
         (completeMethod)="filterCreditors($event)" 
         (onSelect)="onSelectCreditor($event)"
         [dropdown]="true" 
         optionLabel="CompanyName"
         placeholder="Select Creditor"
         id="creditors"
         >
         <ng-template let-creditor pTemplate="item">
          <div class="flex align-items-center justify-content-between flex-1">
            <div>{{creditor.CompanyName}}</div>

            <div>{{creditor.CreditorId}}</div>
          </div>
      </ng-template>
   </p-autoComplete>
      <label for="creditors">Creditor</label>
   </p-floatLabel>
    
   <div class="w-5 mt-6">
    <p-floatLabel>      
      <input [(ngModel)]="creditCardGateWay" class="w-full" type="text" pInputText id="creditCardGateWay" />
      <label for="creditCardGateWay">Credit Card Gateway</label>
    </p-floatLabel>
  </div>


  <div class="w-5 mt-6">
    <p-floatLabel>      
      <input [(ngModel)]="debitCardGateWay" class="w-full" type="text" pInputText  id="debitCardGateWay" />
      <label for="debitCardGateWay">Debit Card Gateway</label>
    </p-floatLabel>
  </div>

  <div class="w-5 mt-6">
    <p-floatLabel>      
      <input [(ngModel)]="bankAccountGateWay" class="w-full" type="text" pInputText id="bankAccountGateWay" />
      <label for="bankAccountGateWay">Bank Account Gateway</label>
    </p-floatLabel>
  </div>

  <div class="flex justify-content-end w-5 mt-6">
    <p-button (click)="onClickSubmit()" styleClass="text-xs" label="Submit" icon="pi pi-send text-xs" [outlined]="true" severity="secondary"></p-button>  
  </div>
 
  </div>
</div>