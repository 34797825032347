import { Component } from '@angular/core';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { PaymentGatewayConfigService } from '../../services/payment-gateway-config/payment-gateway-config.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-payment-gateway-config',
  templateUrl: './payment-gateway-config.component.html',
  styleUrl: './payment-gateway-config.component.css'
})
export class PaymentGatewayConfigComponent {
  public creditors: any | null = null;
  public selectedCreditor: any | null = null;
  public filteredCreditors: any[] = [];
  public creditCardGateWay: string = '';
  public debitCardGateWay: string = '';
  public bankAccountGateWay: string = '';
  
  
  public constructor(
    private etlIngestionService: EtlIngestionService, 
    private paymentGatewayConfigService: PaymentGatewayConfigService,
    public appService: AppService
  ){}

  ngOnInit() {    
    const loggedUserSession = localStorage.getItem('userSession');
    if (loggedUserSession) {
      const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
      console.log('bearer token:',loggedUserToken );
    }

    this.etlIngestionService.getCreditors().subscribe((res:any) => {
     if(!!res && !!res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
       this.creditors = res.Creditors;
     }   
    });
 }

  filterCreditors(event: any) {
    let filteredItems: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.creditors as any[]).length; i++) {
        let item = (this.creditors as any[])[i];
        if (item.CreditorId.indexOf(query) !== -1
        || item.CompanyName.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ) {
            filteredItems.push(item);
        }
    }

    this.filteredCreditors = filteredItems;
  }

  onSelectCreditor(event:any){    
    this.appService.pageSpinnerVisible = true;
    this.paymentGatewayConfigService.getPaymentGatewayConfig('creditor/'+this.selectedCreditor.CreditorId).subscribe((res:any) => {
      this.appService.pageSpinnerVisible = false;
      this.debitCardGateWay = res.debitCardGateWay;
      this.creditCardGateWay = res.creditCardGateWay;
      this.bankAccountGateWay = res.bankAccountGateWay;  
     });
  }

  onClickSubmit(){
    this.appService.pageSpinnerVisible = true;
    let debitCardGateway = this.debitCardGateWay? this.debitCardGateWay : 'Unknown';
    let creditCardGateway = this.creditCardGateWay? this.creditCardGateWay : 'Unknown';
    let bankAccountGateway = this.bankAccountGateWay? this.bankAccountGateWay : 'Unknown';

    this.paymentGatewayConfigService.putPaymentGatewayConfig(creditCardGateway, debitCardGateway, bankAccountGateway, 'creditor/'+this.selectedCreditor.CreditorId)
    .pipe(
      tap(() => {
        this.appService.primeDialogHeader  = 'Success';
        this.appService.primeDialogMessage = 'Payment Gateway Config has been successfully updated.';
      }),
      catchError(error => {
        this.appService.primeDialogHeader  = 'Error';
        this.appService.primeDialogMessage = 'There seems to be an error saving the tag.';
        return of(null); 
      }),
      finalize(() => {
        this.appService.primeDialogVisible = true;
        this.appService.pageSpinnerVisible = false;
      })
    )
    .subscribe();
  }
  
}
